$gray: #d9e0e6;
$gray-light: #EDEDED;
$gray-default: #A9AEB3;
$gray-dark: #484B4D;
$gray-darkness: #231F20;
$green: #8BD42D;
$orange: #F36C48;
$blue-gray: #8F9BB3;
$blue: #233674;
$blue-light: #EFF4FA;
$blue-check: #D8E5F8;
$blue-border: #3656BF;
$bg-white: #F8F8F8;

.color-blue-icon{
    filter: invert(16%) sepia(86%) saturate(1228%) hue-rotate(205deg) brightness(89%) contrast(91%);
}

.color-white-icon{
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(65deg) brightness(107%) contrast(101%);
}

.color-gray-icon{
    filter: invert(78%) sepia(7%) saturate(233%) hue-rotate(169deg) brightness(91%) contrast(82%);
}
