@keyframes fromleft {
  0% { transform: translateX(20px); opacity: 0 };
  100% { transform: translateX(0px); opacity: 1 };
 }

 @keyframes riseup {
  0% { transform: translateY(20px); opacity: 0 };
  100% { transform: translateY(0px); opacity: 1 };
 }

 @keyframes riseup-plus {
  0% { transform: translateY(120px); opacity: 0 };
  100% { transform: translateY(0px); opacity: 1 };
 }

 @keyframes falloff {
  0% { transform: translateY(-20px); opacity: 0 };
  100% { transform: translateY(0px); opacity: 1 };
 }

 @keyframes falloff-plus {
  0% { transform: translateY(-120px); opacity: 0 };
  100% { transform: translateY(0px); opacity: 1 };
 }

 @keyframes fadein {
  0% { opacity: 0 };
  100% { opacity: 1 };
 }

 @keyframes comeup {
  0% { transform-style: preserve-3d; opacity: 0; transform: rotateX(-90deg) translateY(300px); };
  100% { transform-style: preserve-3d; opacity: 1; transform: rotateX(0deg) translateY(0px); };
 }

 @keyframes comedown {
  0% { transform-style: preserve-3d; opacity: 0; transform: rotateX(-90deg) translateY(-300px); };
  100% { transform-style: preserve-3d; opacity: 1; transform: rotateX(0deg) translateY(0px); };
 }

