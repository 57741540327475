.icon-preset-styles {
  background-size: contain !important;
  display: block;
  flex-shrink: 0
}

.logout-icon {
  background: url("../../assets/svg/logout.svg") no-repeat center;
  background-size: contain;
  display: block;
  flex-shrink: 0;
}

.arrow-up-icon {
  background: no-repeat center url("../../assets/svg/arrow-up.svg");
  background-size: contain;
  width: 0.865rem;
  height: 0.673rem;
  display: block;
  flex-shrink: 0;
}

.arrow-down-icon {
  background: url("../../assets/svg/arrow-down.svg") no-repeat center;
  background-size: contain;
  width: 0.865rem;
  height: 0.673rem;
  display: block;
  flex-shrink: 0;
}

.shild-icon {
  background: url("../../assets/svg/shild.svg") no-repeat center;
  background-size: contain;
  width: 0.865rem;
  height: 0.673rem;
  display: block;
}

.loading-icon {
  background: url("../../assets/svg/loading.svg") no-repeat center;
  background-size: contain;
  width: 6rem;
  height: 6rem;
  display: block;
  flex-shrink: 0;
}

.image-file-icon {
  background: url("../../assets/svg/image-file.svg") no-repeat center;
  background-size: contain;
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  flex-shrink: 0;
}

.video-file-icon {
  background: url("../../assets/svg/video-file.svg") no-repeat center;
  background-size: contain;
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  flex-shrink: 0;
}

.text-file-icon {
  background: url("../../assets/svg/text-file.svg") no-repeat center;
  background-size: contain;
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  flex-shrink: 0;
}

.google-drive-icon {
  background: url("../../assets/svg/drive.svg") no-repeat center;
  background-size: contain;
  width: 3.125rem;
  height: 3.125rem;
  display: block;
  flex-shrink: 0;
}

.simple-google-drive-icon {
  background: url("../../assets/svg/simple-google-drive.svg") no-repeat center;
  background-size: contain;
  width: 1.5625rem;
  height: 1.5625rem;
  display: block;
  flex-shrink: 0;
}

.menu-expand-icon {
  background: url("../../assets/svg/menu-expand.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.house-icon {
  background: url("../../assets/svg/house.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.building-icon {
  background: url("../../assets/svg/building.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.degree-hat-icon {
  background: url("../../assets/svg/degree-hat.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.user-setting-icon {
  background: url("../../assets/svg/user-setting.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.file-import-icon {
  background: url("../../assets/svg/file-import.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.arrows-up-down-icon {
  background: url("../../assets/svg/arrows-up-down.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.gear-fill-icon {
  background: url("../../assets/svg/gear-fill.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.globe-icon {
  background: url("../../assets/svg/globe.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.magnifier-icon {
  background: url("../../assets/svg/magnifier.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.side-by-side-users-icon {
  background: url("../../assets/svg/side-by-side-users.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.edit-user-icon {
  background: url("../../assets/svg/edit-user.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.paper-arrow-down-icon {
  background: url("../../assets/svg/paper-arrow-down.svg") no-repeat center;
  background-size: contain;
  display: block;
}

.trash-icon {
  background: url("../../assets/svg/trash1.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.users-vinculate-icon {
  background: url("../../assets/svg/users-vinculate.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.user-badge-icon {
  background: url("../../assets/svg/user-badge.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.user-status-icon {
  background: url("../../assets/svg/user-status.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.download-icon {
  background: url("../../assets/svg/octicons-cloud-download.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.upload-icon {
  background: url("../../assets/svg/upload.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.arrow-right-icon {
  background: url("../../assets/svg/arrow.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.arrow-top-icon {
  background: url("../../assets/svg/arrow.svg") no-repeat center;
  @extend .icon-preset-styles;
  flex-shrink: 0;
}

.arrow-bottom-icon {
  background: url("../../assets/svg/arrow.svg") no-repeat center;
  @extend .icon-preset-styles;
  flex-shrink: 0;
}

.book-with-bookmark-icon {
  background: url("../../assets/svg/book-with-bookmark.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.check-fill-icon {
  background: url("../../assets/svg/check-fill.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.clock-pending-icon {
  background: url("../../assets/svg/clock-pending.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.circle-x-fill-icon {
  background: url("../../assets/svg/circle-x-fill.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.comment-outline-icon {
  background: url("../../assets/svg/comment-outline.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.circle-x-outline-icon {
  background: url("../../assets/svg/circle-x-outline.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.check-outline-icon {
  background: url("../../assets/svg/check-outline.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.pen-editing-icon {
  background: url("../../assets/svg/pen-editing.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.trash-can-icon {
  background: url("../../assets/svg/trash-can.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.attention-icon {
  background: url("../../assets/svg/attention.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.pinned-icon {
  background: url("../../assets/svg/pinned.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.comment-outlined-icon {
  background: url("../../assets/svg/comment-outlined.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.keyboard-arrow-left-icon{
  background: url("../../assets/svg/keyboard-arrow-left.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.filter-outline-icon{
  background: url("../../assets/svg/filter-outline.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.plus-icon{
  background: url("../../assets/svg/plus-icon.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.like-outline-icon{
  background: url("../../assets/svg/like-outline.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.bell-outline-icon{
  background: url("../../assets/svg/bell-outline.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.reply-outline-icon{
  background: url("../../assets/svg/reply-outline.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.alert-circle-outline-icon{
  background: url("../../assets/svg/alert-circle-outline.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.user-cog-outline-icon{
  background: url("../../assets/svg/user-cog-outline.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.overflow-menu-vertical-icon{
  background: url("../../assets/svg/overflow-menu-vertical.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.info-circle-fill-icon{
  background: url("../../assets/svg/info-circle-fill.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.ban-outfill-icon{
  background: url("../../assets/svg/ban-outfill.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.circle-chevron-left-outfill-icon{
  background: url("../../assets/svg/circle-chevron-left-outfill.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.circle-chevron-right-outfill-icon{
  background: url("../../assets/svg/circle-chevron-right-outfill.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.periods-with-config-icon{
  background: url("../../assets/svg/periods-with-config.svg") no-repeat center;
  background-size: contain;
  display: block;
}

.periods-icon{
  background: url("../../assets/svg/periods.svg") no-repeat center;
  background-size: contain;
  display: block;
}

.dots-three-vertical-icon{
  background: url("../../assets/svg/dots-three-vertical.svg") no-repeat center;
  background-size: contain;
  display: block;
}

.calendar-outline-2-icon {
  background: url("../../assets/svg/calendar-outline-2.svg") no-repeat center;
  background-size: contain;
  display: block;
}

.grades-settings-icon {
  background: url("../../assets/svg/grades-settings.svg") no-repeat center;
  background-size: contain;
  display: block;
}

.search-icon {
  background: url("../../assets/svg/search-icon-outline.svg") no-repeat center;
  background-size: contain;
  display: block;
}
/*       _----------------_        */
/*-=====_| CONTENT TYPES |_=====- */
/*       -----------------        */

.book-fill-icon {
  background: url("../../assets/svg/content-types/book-fill.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.book-open-fill-icon {
  background: url("../../assets/svg/content-types/book-open-fill.svg") no-repeat center;
  @extend .icon-preset-styles;
}


.camera-fill-icon {
  background: url("../../assets/svg/content-types/camera-fill.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.chat-image-icon {
  background: url("../../assets/svg/content-types/chat-image.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.checked-plate-icon {
  background: url("../../assets/svg/content-types/checked-plate.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.compressed-file-icon {
  background: url("../../assets/svg/content-types/compressed-file.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.copy-symbol-icon {
  background: url("../../assets/svg/content-types/copy-symbol.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.double-chat-icon {
  background: url("../../assets/svg/content-types/double-chat.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.google-drive-icon {
  background: url("../../assets/svg/content-types/google-drive-fill.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.text-file-fill-icon {
  background: url("../../assets/svg/content-types/text-file-fill.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.youtube-fill-icon {
  background: url("../../assets/svg/content-types/youtube-fill.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.google-forms-icon {
    background: url("../../assets/svg/content-types/forms-google.svg") no-repeat center;
    @extend .icon-preset-styles;
  }

/*       _---------------------_         */
/*-=====_| END OF CONTENT TYPES |_=====- */
/*       -----------------------         */

.certificate-dash-icon {
  background: url("../../assets/svg/certificates.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.circle-exclamation-fill-icon {
  background: url("../../assets/svg/user-communication/circle-exclamation-fill.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.check-slim-fill-icon {
  background: url("../../assets/svg/check-slim-fill.svg") no-repeat center;
  flex-shrink: 0;
}

.circle-interrogation-icon {
  background: url("../../assets/svg/interrogation-circle.svg") no-repeat center;
  flex-shrink: 0;
}

.drag-drop-key-icon {
  background: url("../../assets/svg/drag-drop-key.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.thin-plus-icon {
  background: url("../../assets/svg/buttons/thin-plus.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.fat-plus-icon {
  background: url("../../assets/svg/buttons/fat-plus.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.plus-fill-icon {
  background: url("../../assets/svg/plus.svg") no-repeat center;
  @extend .icon-preset-styles;
}
.sketchbook-outline-icon {
  background: url("../../assets/svg/sketchbook-outline.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.calendar-outline-icon {
  background: url("../../assets/svg/calendar-outline.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.arrow-icon {
  background: url("../../assets/svg/arrow.svg") no-repeat center ;
  @extend .icon-preset-styles;
}

.check-icon {
  background: url("../../assets/svg/check.svg") no-repeat center ;
  @extend .icon-preset-styles;
}

.trash {
  position: absolute;
  right: 10px;
  bottom: 10px;
  content: url("../../assets/svg/certificate/trash.svg");
}

.pencil {
  position: absolute;
  right: 43px;
  bottom: 10px;
  content: url("../../assets/svg/certificate/pencil.svg");
}

.star {
  margin-right: 5px;
  content: url("../../assets/svg/certificate/star.svg");
}

.yellow_star {
  margin-right: 5px;
  content: url("../../assets/svg/certificate/yellow_star.svg");
}

.unknownImg {
  width: 33.33px;
  height: 33.33px;
  content: url("../../assets/svg/certificate/unknownImg.svg");
}

.video-content-icon {
  content: url("../../assets/svg/certificate/video.svg");
  @extend .icon-preset-styles;
}

.complementary-content-icon {
  content: url("../../assets/svg/certificate/chain.svg");
  @extend .icon-preset-styles;
}

.text-content-icon {
  content: url("../../assets/svg/certificate/paper.svg");
  @extend .icon-preset-styles;
}

.interactive-content-icon {
  content: url("../../assets/svg/certificate/gamepad.svg");
  @extend .icon-preset-styles;
}

.default-content-icon {
  content: url("../../assets/svg/certificate/paper.svg");
  @extend .icon-preset-styles;
}

.fallen-v-arrow-icon {
  background: url("../../assets/svg/fallen-v-arrow.svg") no-repeat center ;
  @extend .icon-preset-styles;
}

.tag-icon {
  background: url("../../assets/svg/tag.svg") no-repeat center ;
  @extend .icon-preset-styles;
}

.italic-t-icon {
  background: url("../../assets/svg/italic-t.svg") no-repeat center ;
  @extend .icon-preset-styles;
}

.x-icon {
  background: url("../../assets/svg/x.svg") no-repeat center ;
  @extend .icon-preset-styles;
}

.html-tags-icon {
  background: url("../../assets/svg/html-tags.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.question-mark-icon {
  background: url("../../assets/svg/help-header.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.img-icon-icon {
  background: url("../../assets/svg/img.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.img-notification-icon {
  background: url("../../assets/svg/notification.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.img-notification-vizualized-icon {
  background: url("../../assets/svg/notification-vizualized.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.book-notification-icon {
  background: url("../../assets/svg/notifications/notification-book.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.hat-notification-icon {
  background: url("../../assets/svg/notifications/hat-notification.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.msg-forum-notification-icon {
  background: url("../../assets/svg/notifications/message-forum.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.letter-notification-icon {
  background: url("../../assets/svg/notifications/notification-letter.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.bell-notification-icon {
  background: url("../../assets/svg/notifications/notfication-bell.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.clock-contents-icon {
  background: url("../../assets/svg/clock-contents.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.success-circle-icon-icon {
  background: url("../../assets/svg/icon-check-success.svg") no-repeat center;
  @extend .icon-preset-styles;
}

.success-toastr-icon {
    background: url("../../assets/svg/toastr/success.svg") no-repeat center;
    @extend .icon-preset-styles;
  }
