@import "/src/app/styles/__colors.scss";
@import "/src/app/styles/__mixins.scss";
@import "/src/app/styles/_buttons";
@import "./app/styles/__icons.scss";
@import "./app/styles/__components.scss";
@import "./app/styles/__animations.scss";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html {
  font-family: "Roboto", "Open Sans", sans-serif;
  background-color: $bg-white;
  --filter-white: invert(100%) sepia(44%) saturate(0%) hue-rotate(202deg)
    brightness(111%) contrast(100%);
  --filter-naval: invert(18%) sepia(15%) saturate(7416%) hue-rotate(212deg)
    brightness(86%) contrast(90%);
  --filter-grey: invert(73%) sepia(1%) saturate(1409%) hue-rotate(174deg)
    brightness(95%) contrast(88%);
  --filter-orange: invert(40%) sepia(100%) saturate(577%) hue-rotate(314deg)
    brightness(107%) contrast(84%);
  --filter-green: invert(69%) sepia(73%) saturate(505%) hue-rotate(38deg)
    brightness(94%) contrast(83%);
  --filter-dark: invert(28%) sepia(6%) saturate(346%) hue-rotate(161deg)
    brightness(92%) contrast(87%);
  --filter-light-blue: invert(89%) sepia(5%) saturate(1244%) hue-rotate(187deg)
    brightness(102%) contrast(95%);
  --filter-naval-blue: invert(16%) sepia(66%) saturate(1619%) hue-rotate(209deg)
    brightness(92%) contrast(92%);
  --filter-vivid: brightness(1.1) saturate(1.3);
  --color-naval: #233674;
  --color-orange: #ea6368;
  --color-grey: #a9aeb3;
  --color-light-grey: #e3e4e4;
  --color-green: #80cc28;
  --color-dark: #484b4d;
  --color-light-blue: #d8e5f8;
  --color-dark-white: #f4f4f4;

  /* Remove flechas de alteração de valor nos inputs que tem numeral */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

body {
  overflow: hidden;
  padding-bottom: env(safe-area-inset-bottom);
}

::placeholder,
::-moz-placeholder,
::-webkit-input-placeholder {
  font-family: "Open Sans", sans-serif;
  color: #a2a9b0;
  font-size: 1rem;
}

a {
  cursor: pointer;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}
.shadow-card {
  box-shadow: 0px 4px 15px rgb(0 0 0 / 15%);
}

.round-card {
  border-radius: 0.8rem;
}

//toast
/* #toast-container > .toast-custom {
  content: url(../src/assets/svg/toastr/info.svg);
}


.toast-custom {
  background-color: purple;
} */

/* #toast-container .toast.toast-info {
  background-image: url(../src/assets/svg/toastr/info.svg) !important;
}    */

#toast-container > .toast-info {
  color: #233674;
  background: white;
  position: relative;
}

#toast-container > .toast-info::before {
  content: "";
  background: url(../src/assets/svg/toastr/info.svg) no-repeat center;
  background-size: contain;
  position: absolute;
  left: 10px;
  top: calc(50% - 19px);
  font-size: 20px;
  line-height: 18px;
  width: 30px;
  height: 30px;
  //margin-left: -2px;
}
#toast-container > .toast-success {
  color: #233674;
  background: white;
  position: relative;
}

#toast-container > .toast-success::before {
  content: "";
  background: url(../src/assets/svg/toastr/success.svg) no-repeat center;
  background-size: contain;
  position: absolute;
  left: 10px;
  top: calc(50% - 19px);
  font-size: 20px;
  line-height: 18px;
  width: 30px;
  height: 30px;
  //margin-left: -2px;
}
#toast-container > .toast-error {
  color: #233674;
  background: white;
  position: relative;
}

#toast-container > .toast-error::before {
  content: "";
  background: url(../src/assets/svg/toastr/error.svg) no-repeat center;
  background-size: contain;
  position: absolute;
  left: 10px;
  top: calc(50% - 19px);
  font-size: 20px;
  line-height: 18px;
  width: 30px;
  height: 30px;
  //margin-left: -2px;
}

button.toast-close-button{
    color: #000;
    opacity: 0.4;
}

//checkbox
#toogle {
  height: 1.4rem;
}
#toogle:before {
  will-change: transform;
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  top: calc(50% - 9px);
  left: 4%;
  background: white;
  transition: 200ms cubic-bezier(0.83, 0.02, 0.65, 1.2);
}

#toogle:checked {
  background: var(--color-naval);
}

#toogle:checked:before {
  left: 52%;
}

.hover {
  transition: ease-in-out 0.7s;
  &:hover {
    @include hBlue;
  }
}

.scroll-thin-grey {
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.btn-animate {
  -webkit-transition-duration: 0.4s;
  /* Safari */
  transition-duration: 0.4s;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.btn-animate:after {
  content: "";
  background: #dedede;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  border-radius: 20px;
  transition: all 0.9s;
}

.btn-animate:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.btn-animate:hover {
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
}

.container_scroll {
  margin-top: 15px;
  // max-height: 400px; Defina o max-height para o tamanho que você deseja
  overflow-y: scroll;
  overflow-x: hidden;
}

.container_scroll::-webkit-scrollbar-track {
  width: 7px;
  background: rgba(54, 86, 191, 0.05);
  border-radius: 2px;
}

.container_scroll::-webkit-scrollbar {
  width: 7px;
  background: rgba(54, 86, 191, 0.05);
  border-radius: 2px;
}

.container_scroll::-webkit-scrollbar-thumb {
  width: 7px;
  background: #3656bf;
}

.ngx-charts .grid-panel.odd rect {
  fill: white !important; /* Defina o fundo como branco */
}
