.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  flex-direction: column;
  overflow: hidden;

  cursor: pointer;
  scroll-behavior: smooth;

  transition: 300ms 100ms ease;
  animation: dropdown-closed-overflow 0ms linear 0s forwards;

  &.is-expanded {
    animation: dropdown-expanded-overflow 600ms linear 0s forwards;
  }
}

@keyframes dropdown-expanded-overflow {
  0% {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  100% {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@keyframes dropdown-closed-overflow {
  100% {
    overflow: hidden;
  }
}

.dropdown::after {
  transition: 200ms ease;
}

.item {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  transition: 200ms ease, font-weight 0ms;;
}

.spacer-block {
  width: 30px;
  height: 24px;
  margin-left: auto;
  transition: 300ms 100ms ease;
}
