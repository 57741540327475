@import './__colors.scss';

@mixin hBlue {
    background-color: darken($blue , 8%);
    transition: ease-in-out 0.7s;
}

@mixin transition-ease {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@mixin light-box-shadow {
  box-shadow: 0 0 14px -4px rgba($color: #233674, $alpha: 0.08);
}
