.decision-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;

  user-select: none;
  width: 100%;

  &.right {
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
  }

  &.left {
    justify-content: flex-start;
  }

  .btn {
    display: flex;
    align-items: center;
    padding: 0px 26px;
    height: 40px;

    transition: all 200ms;
    @include transition-ease;

    font: 700 18px Roboto, sans-serif;
    cursor: pointer;
    border-radius: 5px;

    &.forward {
      background: #233674;
      color: #fff;

      &:hover {
        background: #3555bc;
      }

      &.disabled {
        pointer-events: none;
        background: rgb(96, 96, 96);
      }
    }

    &.backward {
      color: #233674;

      border: 2px solid #233674;

      &:hover {
        border-color: #3555bc;
        color: #3555bc;
      }
    }
  }
}
