.option-button {
    width: 184px;
    height: 39px;
    border: 1px solid #D8E5F8;
    box-sizing: border-box;
    border-radius: 20px;
     p{
    pointer-events: visible;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #233674;
     }
     img{
        pointer-events: visible;
         margin-top: 6%;
         margin-left: 3%;
        content: url('../../assets/svg/arrow-down.svg');
    }
    }

.resourcesButton {
    width: 261px;
    height: 40px;

    border: 1px solid #233674;
    box-sizing: border-box;
    border-radius: 4px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    color: #233674;
}

.button-certificate {
height: 37px;
border-radius: 4px;
border: 1px solid #F36C48;
padding-right: 11px;
p {
    margin: 0 6%;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #E8532C;
    span{
        margin-top: 2%;
        margin-right: 3%;
        img{
            content: url('../../assets/svg/certificate/certificate.svg');
            max-width: inherit;
        }
    }
}
}

.button-save {
width: 152px;
height: 37px;
border-radius: 4px;
background: #233674;
p{
font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 16px;
color: #FFFFFF;
    }
}
.button-cancel {
width: 152px;
height: 37px;
border-radius: 4px;
background: #FFFFFF;;
p{
font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 16px;
color: #233674;
    }
}

.button-edit {
    width: 150px;
    height: 37px;
    border-radius: 4px;
    border: 1px solid #233674;
    p {
        margin-left: 6%;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #233674;
        span{
            margin-top: 1px;
            margin-right: 3%;
            img{
                content: url('../../assets/svg/certificate/paper-blue.svg');
            }
        }
    }
    }

.button-secondary {
  @apply flex items-center justify-center py-1.5 px-3 rounded-b-md rounded-t-md text-18 border-2;

  color: var(--color-naval);

  &:hover {
    filter: var(--filter-vivid);
  }
}
